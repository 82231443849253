.all_chars{
    padding: 0px 3px;
    margin:2px 1px;
    margin-left: 6px;
    display: inline-block;
    border-radius: 5px;
    cursor: pointer;
    border: 1px solid black;
    color: rgb(0, 0, 0);
    font-size: .65em;
}
.all_chars:hover{
    background-color:#1c2aee;
}
.tag_keyword{
    display: inline-block;
    margin:0px 0px;
    border-radius: 2px;
    padding: 3px 8px;
    margin-left: 5px;
    margin-right: 0px;
    font-weight: 600;
    border: 1px solid black;
}
.tags_container{
    margin-top: 5px;
    margin-bottom: 10px;
    
}
#div_for_p{
    display: inline-block;   
}
.treemap_class{
    border: 4px solid rgb(0, 117, 163);
    /* if following is not used it will show the depth */   
}

.treemap_class_rev{
    /* border: 4px solid rgb(0, 117, 163); */
    opacity:0.4;
     
}