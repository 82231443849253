.tooltip_matches {
    position: absolute;
    width: 375px;
    padding: 5px;
    pointer-events: none;
    background-color: rgb(152, 160, 168);
    border-radius: 5px;
  }
.tooltip_categories {
    position: absolute;
    /* width: 375px; */
    padding: 5px;
    pointer-events: none;
    background-color: rgb(152, 160, 168);
    border-radius: 5px;
  }
.s_title{
  margin:"0 auto";
  text-align: center;
  font-size: 13px;
  overflow: hidden;
   text-overflow: ellipsis;
   display: -webkit-box;
   -webkit-line-clamp: 3; /* number of lines to show */
           line-clamp: 3; 
   -webkit-box-orient: vertical;
}
 