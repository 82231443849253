.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.matched_attrs{cursor: pointer}
.dataset_name{cursor: pointer}
  .unmatched_attrs_container{
    margin: 5px;
  }
.unmatched_attrs{
  cursor: pointer;
    font-size: 12px;
    display: inline-block;
    margin: 2px 2px;
    border:1px solid rgba(195, 195, 195);
    padding:2px 4px;
  }
div.tooltip {
position: absolute;text-align: center;padding: 5px;				
font: 12px sans-serif;color: white;background: rgb(0, 0, 0);border: 0px;border-radius: 3px;pointer-events: none;			
}
.line_hoverd{
  stroke: rgb(62, 62, 62);
  opacity: 1!important;
}
.title_text{
  text-transform: capitalize;
}
div.tooltip2 {
  position: absolute;text-align: center;height: 28px;padding: 5px;width: 80;				
  font: 12px sans-serif;color: white;background: rgb(0, 0, 0);border: 0px;border-radius: 3px;
  }
 
